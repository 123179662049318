<template>
  <v-list
    :max-height="($vuetify.breakpoint.height - 400).toString()"
    width="100%"
    class="mt-3"
    :style="dragArea ? `height: ${($vuetify.breakpoint.height - 400).toString() + 'px'}; margin-left: 10px; margin-right: 10px; overflow-y: auto; overflow-x: hidden; outline-style: dashed;` : ''"
  >
    <draggable
      :list="documents"
      group="docs"
      :sort="false"
      class="dragArea list-group"
      @change="addReqDoc"
    >
      <div
        v-for="doc in documents"
        :key="doc.id"
      >
        <div
          class="list-group-item my-2 mx-2"
        >
          <v-list-item
            :style="`height: 50px; border-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
          >
            <v-list-item-icon>
              <v-icon
                color="primary"
                class="mt-n1"
              >
                mdi-file-hidden
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="translateFileInputField(doc)"
              />
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
                small
                @click.stop="$emit('delete-required-doc', doc)"
              >
                mdi-close
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </div>
      </div>
      <div
        v-if="!dragArea && !documents.length"
        style="position: absolute; left: 25%; top: 50%; font-size: 15px;"
      >
        {{ $t('mixed|no_required_workbench_documents') }}
      </div>
    </draggable>
  </v-list>
</template>

<script>
import draggable from 'vuedraggable'
import generalMixin from '@/utils/generalMixin'

export default {
  components: { draggable },
  mixins: [generalMixin],
  props: {
    documents: {
      type: Array,
      default: null
    },
    dragArea: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addReqDoc (evt) {
      this.$emit('newWorkbenchDocumentCaseModel', evt)
    }
  }
}
</script>

<style lang="scss" scoped>
  .document-list-container {
    flex-grow: 1;
    max-width: 45vw;
    max-height: 52vh;
    overflow-y: auto;
  }
</style>

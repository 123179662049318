<template>
  <v-card
    width="150"
    height="140"
  >
    <v-col
      xs="1"
      md="1"
      lg="1"
    >
      <v-sheet
        color="primary"
        class="left mt-n1"
        width="8"
        height="90%"
      />
    </v-col>
    <v-card-text class="mt-n6">
      <div v-if="template.name.length >= 21">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-bold ml-2 mt-n2"
              style="font-size: 14px;"
              v-on="on"
            >
              {{ template.name.slice(0, 21) + '...' }}
              <v-btn
                icon
                x-small
                color="primary"
                @click="$emit('updateTemplateName', template)"
              >
                <v-icon
                  size="16"
                  v-text="icons.edit"
                />
              </v-btn>
            </div>
          </template>
          <span>{{ template.name }}</span>
        </v-tooltip>
      </div>
      <div
        v-else
        class="font-weight-bold ml-2 mt-n2"
        style="font-size: 14px;"
      >
        {{ template.name }}
        <v-btn
          icon
          x-small
          color="primary"
          @click="$emit('updateTemplateName', template)"
        >
          <v-icon
            size="16"
            v-text="icons.edit"
          />
        </v-btn>
      </div>
    </v-card-text>
    <v-card-actions class="actions-position">
      <v-btn
        v-if="!fromDrawer"
        text
        rounded
        x-small
        color="primary"
        :to="`/models/${$route.params._id}/${$route.params.workbenchId}/${template._id}`"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-btn
        v-if="fromDrawer"
        text
        rounded
        x-small
        color="primary"
        :to="`/models/${currentCaseForm._id}/${workbench._id}/${template._id}`"
        @click="$emit('close-drawer');"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-spacer />
      <v-btn
        icon
        x-small
        color="primary"
        class="ml-9"
        @click.stop="$emit('delete', template)"
      >
        <v-icon
          size="16"
          v-text="icons.trash"
        />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    currentCaseForm: {
      type: Object,
      default: null
    },
    fromDrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      selectedTemplate: null,
      navigation: {
        width: 450,
        borderSize: 6
      }
    }
  },
  methods: {
    selectTemplate (template) {
      this.selectedTemplate = template._id
      this.$emit('selectTemplate')
    },
    resetTemplate () {
      this.selectedTemplate = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .stripe-position {
    position: absolute;
  }
  .left {
    position: absolute;
    left: 5px;
  }
  .actions-position {
    position: absolute;
    top: 105px;
  }
  .updateBtn {
    position: absolute;
    right: 8px;
    top: 2px;
  }
</style>

<template>
  <v-card
    style="height: 100%; width: 100%;"
  >
    <v-toolbar
      flat
      class="mt-n4"
      style="position: sticky; top: 0px; z-index: 1;"
    >
      <v-card-title style="position: sticky: top: 10px;">
        {{ $t('documents|required_documents') }}
      </v-card-title>
      <v-btn
        icon
        style="position: absolute; top: 8px; right: 16px;"
        @click="$emit('close-assign-dialog'), resetDialog()"
        @mouseover="hoverCloseButtonIcon = true"
        @mouseleave="hoverCloseButtonIcon = false"
      >
        <v-icon
          v-if="hoverCloseButtonIcon"
          small
        >
          mdi-close
        </v-icon>
        <v-icon
          v-else
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-2">
      <div
        v-if="!availableDocuments.length"
        class="ml-6"
      >
        {{ $t('expressions|no_actual_required_documents') }}
      </div>
      <v-list v-else>
        <v-list-item-group
          v-model="activeDocSuggestions"
          multiple
          dense
        >
          <template v-for="(item, i) in availableDocuments">
            <v-divider
              v-if="i !== 0"
              :key="`divider-${i}`"
            />

            <v-list-item
              :key="`item-${i}`"
              :value="item"
              active-class="deep-purple--text text--accent-4"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    mdi-file-hidden
                  </v-icon>
                </v-list-item-icon>
                <v-hover v-slot:default="{ hover }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="!hover"
                      style="font-size: 14px;"
                    >
                      {{ translateFileInputField(item) }}
                    </v-list-item-title>
                    <span
                      v-else
                      style="font-size: 14px;"
                    >
                      {{ translateFileInputField(item) }}
                    </span>
                  </v-list-item-content>
                </v-hover>

                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="deep-purple accent-4"
                  />
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions style="position: sticky; bottom: 10px; background-color: white;">
      <v-spacer />
      <v-btn
        color="primary"
        rounded
        small
        :disabled="!activeDocSuggestions.length"
        min-width="150"
        @click="$emit('apply-doc-suggestions', activeDocSuggestions); resetDialog()"
      >
        {{ $t('actions|submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import generalMixin from '@/utils/generalMixin'

export default ({
  mixins: [generalMixin],
  props: {
    currentCaseForm: {
      type: Object,
      default: null
    },
    currentWorkbench: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hoverCloseButtonIcon: false,
      activeDocSuggestions: [],
      activeItems: []
    }
  },
  computed: {
    availableDocuments () {
      let availableDocs = []
      if (this.currentWorkbench.requiredDocuments.length) {
        for (let i = 0; i < this.currentCaseForm.documents.length; i++) {
          const doc = this.currentCaseForm.documents[i]
          const match = this.currentWorkbench.requiredDocuments.find(item => item.id === doc.id)
          if (!match) {
            availableDocs.push(doc)
          }
        }
      } else {
        availableDocs.push(...this.currentCaseForm.documents)
      }
      return availableDocs
    }
  },
  methods: {
    resetDialog () {
      this.activeDocSuggestions = []
      this.activeItems = []
    }
  }
})
</script>

<style scoped>
.active {
  background: rgb(207, 209, 207);
}
</style>

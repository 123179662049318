
import { mapState, mapActions } from 'vuex'
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'
import TemplateCardCaseModels from '../TemplateCardCaseModels.vue'
import WorkbenchDocumentList from '../WorkbenchDocumentList.vue'
import AssignWorkbenchDocuments from '../AssignWorkbenchDocuments.vue'

export default {
  components: {
    TemplateCardCaseModels,
    WorkbenchDocumentList,
    AssignWorkbenchDocuments
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      infoDialog: false,
      colsLeft: 6,
      colsRight: 6,
      selectedTemplate: null,
      assignDocDialog: false,
      dragArea: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms
    }),
    currentCaseForm () {
      if (this.currentCaseFormDrawer) return this.currentCaseFormDrawer
      else {
        return this.caseForms.find(c => c._id === this.$route.params._id) || this.companyCaseForms.find(c => c._id === this.$route.params._id) ||
        this.groupCaseForms.find(c => c._id === this.$route.params._id) || []
      }
    },
    currentWorkbench () {
      if (this.currentWorkbenchDrawer) return this.currentWorkbenchDrawer
      else return this.currentCaseForm.workbenches.find(w => w._id === this.$route.params.workbenchId)
    }
    // currentTemplate () {
    //   return this.currentWorkbench.templates.find(t => t._id === this.selectedTemplate)
    // }
  },
  created () {
    EventBus.$on('drag-area-active-case-model', this.onDragAreaActiveCaseModel)
    EventBus.$on('drag-area-not-active-case-model', this.onDragAreaNotActiveCaseModel)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseFormWorkbenchTemplate: 'caseForms/updateCaseFormWorkbenchTemplate',
      addCaseFormWorkbenchTemplate: 'caseForms/addCaseFormWorkbenchTemplate',
      addCaseFormWorkbenchRequiredDocuments: 'caseForms/addCaseFormWorkbenchRequiredDocuments',
      deleteCaseModelWbRequiredDocument: 'caseForms/deleteCaseModelWbRequiredDocument',
      deleteCaseFormWorkbenchTemplate: 'caseForms/deleteCaseFormWorkbenchTemplate'
    }),
    onDragAreaActiveCaseModel () {
      this.dragArea = true
    },
    onDragAreaNotActiveCaseModel () {
      this.dragArea = false
    },
    selectTemplate (template) {
      this.selectedTemplate = template._id
      // this.$emit('selectTemplate')
    },
    resetTemplate () {
      this.selectedTemplate = null
    },
    async addWorkbenchTemplate () {
      EventBus.$emit('dialog-add-template-to-workbench', {
        callback: async (templateRef) => {
          const res = await this.$dialog.prompt({
            title: this.$t('templates|template_name'),
            text: this.$t('actions|enter_template_name'),
            textField: {
              // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text'
            }
          })
          if (!res) return
          try {
            const payload = {
              templateRef,
              name: res
            }
            await this.addCaseFormWorkbenchTemplate({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      })
    },
    async updateTemplateName (template) {
      const res = await this.$dialog.prompt({
        title: this.$t('templates|template_name'),
        text: this.$t('actions|enter_new_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      const payload = {
        name: res
      }
      try {
        await this.updateCaseFormWorkbenchTemplate({
          _id: this.currentCaseForm._id,
          workbenchId: this.currentWorkbench._id,
          templateId: template._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_template_update_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_template_update_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async applyDocSuggestions (activeDocSuggestions, idx, drag) {
      try {
        const payload = {
          documents: activeDocSuggestions,
          index: drag ? idx : null,
          drag: drag
        }
        await this.addCaseFormWorkbenchRequiredDocuments({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
        this.assignDocDialog = false
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async deleteRequiredWbDoc (doc) {
      // delete document from workbench

      const res = await this.$dialog.confirm({
        text: this.$t('warning|delete_document_from_list'),
        title: this.$t('common|warning')
      })
      if (!res) return
      this.loading = true
      const payload = {
        requiredDocToBeDeleted: doc
      }
      try {
        await this.deleteCaseModelWbRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async deleteWorkbenchTemplate (workbench, template) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      await this.deleteCaseFormWorkbenchTemplate({
        _id: this.currentCaseForm._id,
        workbenchId: workbench._id,
        templateId: template._id
      })
    },
    onNewWorkbenchDocumentCaseModel (evnt) {
      this.applyDocSuggestions([evnt.added.element], evnt.added.newIndex, true)
    }
  }
}
